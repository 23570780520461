<template>
  <div :style="'background:' + backgroundColor" class="relative">
    <div class="ad-banner">
      <AdCover :src="imgSrc" @click="handleShopNow" />
    </div>
    <icon-font v-show="!isLocked && closable" type="icon-close-line" class="close-btn" @click.stop="lock" />
    <div v-if="isLocked" class="absolute left-0 top-0 h-full w-full">
      <AdFeedback :type="screenSize.width < 768 ? 'vertical' : 'horizontal'" class="inset-0" @back="unlock" @feedback="handleFeedback" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSensors } from '~/composables';

interface AdItemProps {
  pcImg?: string;
  mobileImg?: string;
  link?: string;
  backgroundColor?: string;
  closable?: boolean;
}
const props = withDefaults(defineProps<AdItemProps>(), {
  pcImg: '',
  mobileImg: '',
  link: '',
  backgroundColor: 'pink',
  closable: true,
});
const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'click'): void;
  (e: 'feedback', ops: string): void;
}>();

const { screenSize } = useScreen();

const w = ref(0);
const updateSize = () => {
  w.value = window.innerWidth;
};
onMounted(() => {
  updateSize();
  window.addEventListener('resize', updateSize);
});
onUnmounted(() => {
  window.removeEventListener('resize', updateSize);
});
const imgSrc = computed(() => {
  if (w.value === 0) {
    return '';
  } else {
    return w.value >= 768 ? props.pcImg : props.mobileImg;
  }
});

const isLocked = ref(false);
const lock = () => {
  isLocked.value = true;
  emits('close');
};
const unlock = () => {
  isLocked.value = false;
};

const handleFeedback = (feedbackContent: string) => {
  unlock();
  emits('feedback', feedbackContent);
};
const { sensorButtonClick } = useSensors();
const handleShopNow = () => {
  emits('click');
  sensorButtonClick({
    element_id: 'shopNow',
  });
  navigateTo(props.link, { open: { target: '_blank' } });
};
</script>

<style scoped lang="less">
@breakpoint: 768px;

.ad-banner {
  position: relative;
  width: 100%;
  max-width: 1920px;
  // border-radius: 8px;
  overflow: hidden;
  cursor: pointer;

  @media (min-width: 1760px) {
    margin: auto;
  }
  @media (min-width: 768px) {
    height: 100px;
  }
  @media (max-width: 767px) {
    padding-top: 47.73%;
    :deep(.ad-cover) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  :deep(.feedback.vertical) {
    .message {
      @media screen and (max-width: 767px) {
        padding: 0 14px;
        margin-bottom: 4px;
      }
      .anticon {
        display: none;
      }
      p {
        @media screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 4px;
        }
      }
    }
    .ops-btns {
      button {
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}
.close-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 12px;
  font-size: 16px;
  padding: 4px;
  color: #575757;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}
:deep(.back-btn) {
  @media screen and (max-width: @breakpoint) {
    font-size: 16px;
  }
}
</style>
