<template>
  <div v-if="items && items.length > 0" class="mb-8 w-full mobile:mb-4">
    <Carousel :items="items" :gap="1" :auto="true" :auto-play-speed="5000">
      <template #item="{ item, index }">
        <a
          :href="item.link"
          target="_blank"
          :aria-label="`Read more about ${item.name}`"
          class="item"
          :data-sensors-exposure-event-name="SENSORS_EVENT.HOMEBANNEREXPOSURE"
          :data-sensors-exposure-property-content_id="item.id"
          :data-sensors-exposure-property-content_name="item.name"
          :data-sensors-exposure-property-publish_time="item.createdAt"
          :data-sensors-exposure-property-show_source="locationHref"
          data-sensors-exposure-property-belong_module="home"
          @click="handleBannerClick(item, index + 1)"
        >
          <picture>
            <source :srcset="item.mbImg" media="(max-width: 768px)" />
            <img :src="item.pcImg" :alt="item.name" />
          </picture>
        </a>
      </template>
      <template #arrow="{ prev, next }">
        <ClientOnly>
          <div :class="['df-banner-btn-wrap', 'h-[64px] w-[32px]', 'absolute left-0 top-[50%] translate-y-[-50%]', 'overflow-hidden']">
            <div class="df-banner-btn prev" @click="prev">
              <icon-font type="icon-icon-right-m" class="text-[24px]" />
            </div>
          </div>
          <div :class="['df-banner-btn-wrap', 'h-[64px] w-[32px]', 'absolute right-0 top-[50%] translate-y-[-50%]', 'overflow-hidden']">
            <div class="df-banner-btn next" @click="next">
              <icon-font type="icon-a-icon-right-m1" class="text-[24px]" />
            </div>
          </div>
        </ClientOnly>
      </template>
    </Carousel>
  </div>
</template>

<script setup lang="ts">
import Carousel from '@/components/common/carousel/index.vue';
import { SENSORS_EVENT } from '~/types';
import { useBanner, useEventTrack } from './hooks';

const items = await useBanner();
const { handleBannerClick, locationHref } = useEventTrack();
</script>

<style lang="less" scoped>
.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
:deep(.df-carousel-wrap) {
  padding-bottom: 22px !important;
  //&::after {
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  z-index: 0;
  //  left: 0;
  //  bottom: 0;
  //  width: 100%;
  //  height: 40px;
  //  background: linear-gradient(180deg, rgba(245, 247, 251, 0) 0%, #f5f7fb 100%);
  //  pointer-events: none;
  //  @media (max-width: 768px) {
  //    display: none;
  //  }
  //}
}
:deep(.df-carousel) {
  height: auto !important;
  padding-top: 19.44%;
  @media (max-width: 768px) {
    padding-top: 47.73%;
  }
  .df-carousel-track {
    position: absolute;
    top: 0;
  }
}
:deep(.df-banner-btn-wrap) {
  @media (max-width: 768px) {
    display: none;
  }
  .df-banner-btn {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.prev {
      margin-left: -32px;
      .anticon {
        margin-left: 50%;
      }
    }
    &.next {
      .anticon {
        transform: translateX(50%);
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 1);
    }
  }
}
:deep(.df-carousel-dots) {
  z-index: 1;
  bottom: 0 !important;
  span {
    background: #1a1a1a !important;
    opacity: 0.1;
    &.current {
      width: 28px;
      background: #db9440 !important;
      opacity: 1;
    }
  }
}
</style>
