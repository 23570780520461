<template>
  <div class="lane-column mb-12 w-full max-w-[1920px] px-10 mobile:mb-10 mobile:px-2">
    <div class="mb-3 flex items-center justify-between">
      <h5
        :class="[
          `w-full font-bold text-text-primary`,
          `text-[24px] leading-[32px]`,
          `mobile:text-[20px] mobile:leading-[28px]`,
          `overflow-hidden truncate break-all`,
        ]"
      >
        {{ column.title }}
      </h5>
      <df-btn class="mobile:!hidden" @click.prevent="onSeeMore">See more</df-btn>
    </div>
    <Lane :items="items" class="makings">
      <template #item="{ item, index }">
        <BlockCardItem v-if="index < 10" :making-item="item" :opts="{ blockTitle: column.title, blockId: item.blockId }" :is-show-user-card="true" />
        <NuxtLink v-else :to="seeMoreLink" class="see-more-card block h-full w-full rounded-xl p-2">
          <div class="relative h-full w-full overflow-hidden rounded-[4px]">
            <img :src="SEE_MORE_PNG" alt="See more" class="h-full w-full object-cover" />
            <div class="absolute inset-0 bg-[#000]/70 backdrop-blur-[12px]"></div>
            <df-btn type="filled" class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">See more</df-btn>
          </div>
        </NuxtLink>
      </template>
    </Lane>
    <div class="hidden h-12 justify-center mobile:mt-6 mobile:flex">
      <a :href="seeMoreLink" class="text-[0]">creations in the category of {{ column.title }}</a>
      <ClientOnly>
        <df-btn
          type="default"
          shape="round"
          size="large"
          :title="`See more creations in the category of ${column.title}`"
          class="see-more-btn border-black-default font-bold"
          @click.prevent="onSeeMore"
        >
          See more
        </df-btn>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import Lane from '~/components/common/lane/index.vue';

import SEE_MORE_PNG from '~/assets/img/block-card/SeeMore.png';

const props = defineProps({
  column: {
    type: Object as PropType<{ id: number; items: any[]; title: string }>,
    default: () => ({}),
  },
});

const allItems = computed(() => props.column.items);
const items = computed(() => {
  const items = allItems.value.slice(0, 10);
  if (allItems.value.length > 10) {
    items.push({});
  }
  return items;
});

const seeMoreLink = computed(() => `/column/${props.column.id}/?from=premium`);

const { sensorsSeeMore } = useSensors();
const onSeeMore = () => {
  const column = props.column;
  navigateTo(`/column/${column.id}/?from=premium`);

  sensorsSeeMore({
    block_id: column.id,
    block_name: column.title,
    clickSource: 'premium',
  });
};
</script>

<style scoped lang="less">
:deep(.df-lane-wrap) {
  .df-lane {
    @media (max-width: 767px) {
      --c: 1;
    }
    @media (min-width: 768px) {
      --c: 2;
    }
    @media (min-width: 992px) {
      --c: 3;
    }
    @media (min-width: 1200px) {
      --c: 4;
    }
    @media (min-width: 1600px) {
      --c: 5;
    }

    .df-lane-track {
      padding-bottom: 16px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .df-lane-item {
      @media (min-width: 768px) {
        padding-top: 4px;
      }
      @media (max-width: 767px) {
        display: none;
        width: 100%;
        margin: 12px 0 0;
        &:first-child {
          margin: 0;
        }
        &:nth-of-type(-n + 5) {
          display: block;
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
  .df-lane-arrow {
    top: calc(50% - 16px);
    @media (max-width: 767px) {
      display: none;
    }
  }
}
:deep(.see-more-btn) {
  width: 160px;
  height: 48px;
  font-size: 16px;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.see-more-card {
  background-color: #fff;
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    transform: translateY(-4px);
  }
}
</style>
