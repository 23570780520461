import { getPremiumHomeBannerApi } from '~/api/premium-home';

export const useBanner = async () => {
  const { data: items } = await useAsyncData(
    'premium-home-banner',
    async () => {
      try {
        const res = await getPremiumHomeBannerApi.fetch();
        if (res?.code === 0) {
          return res.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            link: item.extra?.link,
            createdAt: item.createdAt,
            pcImg: getOssProcessedImage(item.extra?.cover_pc, '', 'webp'),
            mbImg: getOssProcessedImage(item.extra?.cover_app, '', 'webp'),
          }));
        }
      } catch (err) {
        console.error(err);
      }
    },
    { default: () => [] },
  );
  return items;
};

export const useEventTrack = () => {
  const { sensorsBannerClickEvent, traceBannerClick } = useSensors();
  const handleBannerClick = (banner: any, seq: number) => {
    traceBannerClick({
      content_id: banner.id,
      content_name: banner.name,
      page_name: 'premium',
      sequence: seq,
    });

    sensorsBannerClickEvent({
      content_id: banner.id,
      content_name: banner.name,
      belong_page: window.location.href,
      belong_module: 'home',
    });
  };

  const locationHref = ref('');
  onMounted(() => {
    locationHref.value = window.location.href;
  });
  return {
    handleBannerClick,
    locationHref,
  };
};
