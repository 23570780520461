<template>
  <div class="featured-creator w-full rounded-xl bg-[#FFF] pt-4">
    <div class="profile mb-4 flex items-center justify-between pl-[15px] pr-[20px]">
      <div class="flex w-[calc(100%-108px)]">
        <NuxtLink :to="`/profile/${creator.uid}`" class="avatar relative h-12 w-12 flex-shrink-0 rounded-full bg-background-2">
          <img v-if="creator.headPic" v-lazy="creator.headPic" :alt="creator.creator" class="h-full w-full rounded-full object-cover" />
          <img
            v-if="creator.isPrime"
            :src="PRIME_CREATOR"
            alt=""
            class="absolute bottom-0 right-0 h-[20px] w-[20px]"
            @click.prevent.stop="navigateTo('/creator/guide')"
          />
          <!--          <img v-if="creator.isPrime" :src="PRIME_CREATOR" alt="" class="absolute bottom-0 right-0 h-[20px] w-[20px]" />-->
        </NuxtLink>
        <div class="ml-3 max-w-[calc(100%-64px)]">
          <p
            :class="[`mb-1 w-full`, `text-level-2 font-semibold text-text-primary`, `cursor-pointer overflow-hidden truncate break-all`]"
            @click="navigateTo(`/profile/${creator.uid}`)"
          >
            {{ creator.creator }}
          </p>
          <div class="flex items-center text-text-tertiary">
            <ClientOnly>
              <icon-font type="icon-creative-line" class="ml-[1px] text-[18px]" />
            </ClientOnly>
            <span class="mr-1 text-level-4">{{ creator.makingCount }}</span>
            <span class="text-level-4">Creations</span>
          </div>
        </div>
      </div>
      <FollowButton
        :id="creator.uid"
        :name="creator.creator"
        size="middle"
        shape="round"
        default-type="primary"
        my-follow-type="default"
        :is-my-follow="creator.isFollowed"
        content-type="user"
        @click.prevent=""
        @change="emits('follow')"
      />
    </div>
    <div class="makings px-2 pb-2">
      <div class="ml-[-1px] mt-[-1px] flex w-full flex-wrap overflow-hidden rounded-lg">
        <NuxtLink
          v-for="item in creator.topMakings"
          :key="item.makingId"
          :to="`/${item.type}/${item.makingId}`"
          class="ml-[1px] mt-[1px] block w-[calc((100%-2px)/2)]"
        >
          <div
            class="relative w-full bg-background-2 pt-[75%]"
            :data-sensors-exposure-event-name="SENSORS_EVENT.CONTENT_EXPOSURE"
            :data-sensors-exposure-property-content_id="item.makingId"
            :data-sensors-exposure-property-content_name="item.title"
            :data-sensors-exposure-property-content_show_type="item.type"
            :data-sensors-exposure-property-content_tag="item?.contentTags || []"
            :data-sensors-exposure-property-publish_time="item.pubulishAt"
            :data-sensors-exposure-property-author_name="creator.creator"
            :data-sensors-exposure-property-show_source="route.path"
            :data-sensors-exposure-property-block_name="'feature Creators'"
            :data-sensors-exposure-property-service_type="item.type"
            :data-sensors-exposure-property-content_type="item.forSale ? 'paid' : 'free'"
          >
            <img
              v-lazy="getOssProcessedImage(item.cover, 'm_fill,w_400,h_300', 'webp')"
              :alt="item.type"
              class="absolute left-0 top-0 h-full w-full object-cover"
            />
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import PRIME_CREATOR from '@/assets/img/creator/premium-creator-icon.png';
import type { PropType } from 'vue';
import { SENSORS_EVENT } from '~/types';

defineProps({
  creator: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
});

const emits = defineEmits<{
  (e: 'follow'): void;
}>();

const route = useRoute();
</script>

<style scoped lang="less">
.featured-creator {
  transition-property: box-shadow, transform;
  transition-duration: 0.2s;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  }

  .makings a {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      display: block;
      background: rgba(0, 0, 0);
      opacity: 0;
      transition: opacity 0.2s ease-in;
      pointer-events: none;
    }
    &:hover::after {
      opacity: 0.2;
    }
  }
}
:deep(.ant-btn) {
  height: 36px;
}
</style>
