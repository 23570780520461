<template>
  <div class="grid-column mb-16 w-full max-w-[1920px] px-10 mobile:mb-10 mobile:px-2">
    <h5
      :class="[
        `mb-4 w-full font-bold text-text-primary mobile:mb-3`,
        `text-[24px] leading-[32px]`,
        `mobile:text-[20px] mobile:leading-[28px]`,
        `overflow-hidden truncate break-all`,
      ]"
    >
      {{ column.title }}
    </h5>
    <div class="makings">
      <BlockCardItem
        v-for="item in column.items"
        :key="item.id"
        :making-item="item"
        :opts="{ blockTitle: column.title, blockId: item.blockId }"
        :is-show-user-card="true"
      />
    </div>
    <div class="flex h-12 justify-center">
      <a :href="`/column/${column.id}/?from=premium`" class="text-[0]">creations in the category of {{ column.title }}</a>
      <ClientOnly>
        <df-btn
          type="default"
          shape="round"
          size="large"
          :title="`See more creations in the category of ${column.title}`"
          class="see-more-btn border-black-default font-bold"
          @click.prevent="onSeeMore"
        >
          See more
        </df-btn>
      </ClientOnly>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from 'vue';

const props = defineProps({
  column: {
    type: Object as PropType<any>,
    default: () => ({}),
  },
});
const { sensorsSeeMore } = useSensors();
const onSeeMore = () => {
  const column = props.column;
  navigateTo(`/column/${column.id}/?from=premium`);

  sensorsSeeMore({
    block_id: column.id,
    block_name: column.title,
    clickSource: 'premium',
  });
};
</script>

<style scoped lang="less">
.makings {
  --gap: 16px;

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gap));
  margin-left: calc(-1 * var(--gap));
  margin-bottom: 40px;
  overflow: hidden;

  @media (max-width: 767px) {
    margin-left: 0;
    margin-bottom: 24px;
  }

  :deep(.block-card-item-wrapper) {
    display: none;

    @media (max-width: 767px) {
      &:nth-of-type(-n + 5) {
        display: block;
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      &:nth-of-type(-n + 10) {
        display: block;
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      &:nth-of-type(-n + 9) {
        display: block;
      }
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      &:nth-of-type(-n + 8) {
        display: block;
      }
    }
    @media (min-width: 1600px) {
      &:nth-of-type(-n + 10) {
        display: block;
      }
    }

    @media (max-width: 767px) {
      --c: 1;
    }
    @media (min-width: 768px) {
      --c: 2;
    }
    @media (min-width: 992px) {
      --c: 3;
    }
    @media (min-width: 1200px) {
      --c: 4;
    }
    @media (min-width: 1600px) {
      --c: 5;
    }
    width: calc(100% / var(--c) - var(--gap));
    margin-top: var(--gap);
    margin-left: var(--gap);
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
:deep(.see-more-btn) {
  width: 160px;
  height: 48px;
  font-size: 16px;
  border-width: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
