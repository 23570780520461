<template>
  <div v-if="!isEmpty(items)" class="featured-targets flex w-full justify-center">
    <div class="featured-targets-wrap mb-[36px] w-full max-w-[1920px] px-10 mobile:px-4">
      <Lane :items="items">
        <template #item="{ item }">
          <NuxtLink :to="item.link" :target="item.target" :title="item.title" class="item relative block w-full pt-[58%]">
            <img v-lazy="item.img" :alt="item.title" class="absolute left-0 top-0 h-full w-full object-cover" />
          </NuxtLink>
        </template>
      </Lane>
    </div>
  </div>
</template>
<script setup lang="ts">
import Lane from '~/components/common/lane/index.vue';
import { getRecommendListApi } from '~/api';
import { isEmpty } from 'lodash-es';

const parseLink = (item: any) => {
  const res = { link: '', target: '_blank' };
  switch (item.type) {
    case 'tag':
      res.link = `/all?tagGroups=${item.objectId}`;
      res.target = '_self';
      break;
    case 'collection':
      res.link = `/collection/${item.objectId}`;
      res.target = '_self';
      break;
    default:
      res.link = item.link;
      res.target = '_blank';
      break;
  }
  return res;
};

const { data: items } = await useAsyncData(
  async () => {
    const res = await getRecommendListApi.fetch({
      pageType: 'primeHome',
    });
    const arr = res.data ?? [];
    return arr.map((item) => {
      const { link, target } = parseLink(item);
      return {
        target,
        link,
        title: item.copyWriting,
        img: item.pcImage,
      };
    });
  },
  { default: () => [] as any[] },
);
</script>

<style scoped lang="less">
.featured-targets-wrap {
  @media (max-width: 767px) {
    overflow-x: auto;
    scrollbar-width: none; /* 对于 Firefox 6 */
    &::-webkit-scrollbar {
      display: none; /* 对于 Chrome, Safari 和 Opera */
    }
  }
}
.item {
  border-radius: 12px;
  overflow: hidden;
}
:deep(.df-lane-wrap) {
  @media (max-width: 767px) {
    .df-lane {
      --c: 2;
      --gap: 8px;
      overflow-x: unset;
    }

    .df-lane-arrow {
      display: none;
    }
  }
  .df-lane {
    @media (min-width: 768px) and (max-width: 991px) {
      --c: 4;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      --c: 5;
    }
    @media (min-width: 1200px) and (max-width: 1599px) {
      --c: 6;
    }
    @media (min-width: 1600px) {
      --c: 7;
    }
  }
}
</style>
