<template>
  <div v-if="columns" class="columns flex flex-col items-center">
    <template v-for="column in columns" :key="column.id">
      <GridColumn v-if="column.showType === 'grid' && column.items.length > 0" :column="column" />
      <LaneColumn v-else-if="column.showType === 'lane' && column.items.length > 0" :column="column" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { getBlockApi } from '~/api';
import { BLOCK_TYPE } from '~/stores/types';
import GridColumn from './GridColumn.vue';
import LaneColumn from './LaneColumn.vue';

const [columns] = await getBlockApi.useFetch(
  {
    category: BLOCK_TYPE.PREMIUM_HOME,
  },
  { deep: true },
);
</script>

<style scoped lang="less"></style>
