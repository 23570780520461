<template>
  <div class="mx-auto w-full">
    <Banner />
    <FeaturedTargets />
    <Columns />
    <FeaturedCreators />
    <CreatorAd ad-key="PREMIUM_HOME" type="primeHomeBottom" :source="'premium'" />
  </div>
</template>

<script setup lang="ts">
import Banner from './banner/index.vue';
import Columns from './columns/index.vue';
import FeaturedTargets from './FeaturedTargets.vue';
import FeaturedCreators from './featured-creators/index.vue';
import { useUserInfo } from '~/stores/user';
import { has, omit } from 'lodash-es';

const { $sensors, $client } = useNuxtApp();

const route = useRoute();
const router = useRouter();

const { isLogin } = useLoginStatus();
const userStore = useUserInfo();
// const GIFT_KEY = 'GET_CREDITS_GIFT';
onMounted(() => {
  const query: any = { ...route.query };

  // 校验路由参数是否完整
  if (['orderEmail', 'orderNum', 'credits'].some((key) => !has(query, key))) {
    return;
  }

  // const orderNum = query.orderNum;
  const orderEmail = query.orderEmail;
  const credits = query.credits;

  const userEmail = userStore.myInfo.email;

  // const gift = tryCall(() => parseJSON(localStorage.getItem(GIFT_KEY)));

  if (isLogin.value) {
    /* if (gift?.orderEmail === orderEmail && gift?.orderNum === orderNum) {
      // 已经领取过了
      console.log('Already obtained credits');
    } else */
    if (userEmail === orderEmail) {
      message.success(`Obtained ${credits} credits successfully. Use now!`);
    } else if (userEmail !== orderEmail) {
      message.warning('Please log in with the email used for your xTool order to obtain credits.');
    }
    router.replace({
      path: route.path,
      query: omit(query, ['orderEmail', 'orderNum', 'credits']),
    });
    /* localStorage.setItem(
      GIFT_KEY,
      JSON.stringify({
        orderEmail,
        orderNum,
      }),
    ); */
  } else {
    $client.login();
  }
});

onMounted(() => {
  const global = window as any;
  if (global.premiumVisited) {
    global.premiumVisited = false;
  } else {
    $sensors.quick('autoTrack');
  }
});
</script>
