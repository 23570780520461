<template>
  <div v-if="creators && creators.length > 0" class="featured-creators flex w-full justify-center">
    <div class="w-full max-w-[1920px] px-10 pb-[10px] pt-[26px] mobile:px-2 mobile:pb-[10px] mobile:pt-3">
      <div class="header mb-[22px] flex h-10 items-center justify-between px-2 mobile:mb-3">
        <h5 :class="[`mb-0 font-bold text-text-primary`, `text-[24px] leading-[32px]`, `mobile:text-[20px] mobile:leading-[28px]`]">
          Featured Creators
        </h5>
      </div>
      <Lane :items="creators" :gap="15" :column="3" class="creators">
        <template #item="{ index, item }">
          <Creator :creator="item" @follow="onFollow(index)" />
        </template>
      </Lane>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCreatorListApi } from '~/api';
import Lane from '~/components/common/lane/index.vue';
import Creator from './Creator.vue';

const [creators] = await getCreatorListApi.useFetch(
  {
    pageType: 'primeHome',
  },
  { deep: true },
);
const onFollow = (index: number) => {
  creators.value[index].isFollowed = !creators.value[index].isFollowed;
};
</script>

<style scoped lang="less">
.featured-creators {
  background: #f3e8d6;
}
:deep(.df-lane) {
  @media (min-width: 1600px) {
    --c: 4;
  }
  @media (max-width: 991px) {
    --c: 2;
  }
  @media (max-width: 767px) {
    --c: 1;
  }
  .df-lane-track {
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .df-lane-item {
    @media (min-width: 768px) {
      padding-top: 4px;
      padding-bottom: 16px;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin: 0 0 12px;
    }
  }
}
</style>
